<template>
  <div class="exclusiveClients-view">
    <div class="d-flex justify-content-between flex-wrap-y align-items-center">
      <div class="operation-left">
        <el-button :disabled="multipleSelection.length == 0 " @click="dialogTableVisible = true,dialogType = '移至成员'">移至成员</el-button>
        <el-button :disabled="multipleSelection.length == 0 " @click="turnOverShare">移至共享</el-button>
        
      </div>
      <div class="operation-right d-flex">
        <el-input
          v-model="name"
          class="mr-16 d-flex align-items-center"
          placeholder="请输入电话、姓名或微信号"
        ></el-input>
        <el-button style="height: 32px" type="primary" @click="getClientList">查询</el-button>
        <!-- <el-button style="padding-top: 0; padding-bottom: 0" class="export">
          <div
            style="height: 32px"
            class="d-flex align-items-center justify-content-center"
          >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-save"></use>
            </svg>
            <span>导出</span>
          </div>
        </el-button> -->
      </div>
    </div>
    <commonTable :tableData="tableData" 
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 @handleSelectionChange="handleSelectionChange"
                 :loading="loading"
                 :total="total"
                 >
      <template v-slot:table >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="customerId" align="center" width="80" label="序号" />
        <el-table-column prop="name" align="center" label="姓名" />
        <el-table-column
          prop="phone"
          align="center"
          label="手机号码"
          show-overflow-tooltip
        />
        <el-table-column prop="personalWx" align="center" label="微信号" />
        <el-table-column prop="inputDate" align="center" label="创建时间" />
        <el-table-column prop="customerRemark" align="center" label="备注" />
        <el-table-column label="操作" align="center">
          <template class="operating" slot-scope="scope">
            <el-button size="mini" type="text" @click="dialogTableVisible = true, customerIds = [scope.row.customerId]">移交</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      title="客户企微数据"
      center
      :visible.sync="showWeChatCode"
      :modal-append-to-body="false"
      width="80%"
    >
      <popupWindow></popupWindow>
    </el-dialog>
    <memberList :memberData="memberData"
                :title="dialogType"
                :dialogTableVisible="dialogTableVisible"
                :total="memberTotal"
                @dialogClose="dialogClose"
                @dialogCurrentChange="dialogCurrentChange"
                @turnOverMember="turnOverMember">

    </memberList>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import popupWindow from "./popupWindow";
import memberList from "@/components/common/memberList";
import { getCustomerPC,getCompanyStaffList,moveCustomer } from "@/api/client";
export default {
  name: "exclusiveClients",
  components: {
    commonTable,
    memberList,
    popupWindow
  },
  props:{
    detallId:{
      type :String,
      default:''
    },
  },
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      customerIds:'',
      total:0, //总页数
      pageNum:1,
      pageSize:10,
      loading:false,
      name:'',//查询条件 ： 电话名称
      followName:'',//查询条件 ： 销售员姓名
      dialogTableVisible:false, //成员弹窗
      dialogType:'移至成员',// 弹出框类型
      dialogPageNum:'',
      memberData:[] ,// 
      memberTotal: 0,
      showWeChatCode:false
    };
  },
  created(){
    this.getClientList()
    this.getMember()
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.customerIds =  this.multipleSelection.map(item => item.customerId);
    },
    getClientList() {
      this.loading = true
      let data = {
        type:1,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        customerState: 1,
        cardId:this.detallId,
        name:this.name,
        followName:this.followName
      };
      getCustomerPC(data).then((res) => {
        this.loading = false
        this.tableData = res.data.pageInfo.list;
        this.tableData.forEach(v=>{
          v.selection = false
        })
        this.total = res.data.pageInfo.total;
      })
      .catch(err=>{
        this.tableData = []
        this.loading = false
      })
    },
    getMember(){
      let data = {
        pageNum: this.dialogPageNum,
        queryType:'web'
      }
        getCompanyStaffList(data)
        .then((res)=> {
            this.memberData = res.data.list;
            console.log(this.memberData)
            this.memberTotal = res.data.totalNumber
        });
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getClientList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getClientList()
    },
    //弹出框关闭
    dialogClose(){
      this.dialogTableVisible = false
    },
    //弹出框分页
    dialogCurrentChange(val){
      this.dialogPageNum = val
      this.getMember()
    },
    //确认人员
    turnOverMember(val){
      if(!val){
        this.$message({
          title: "警告",
          message: "请先选择移交的成员",
          type: "warning",
        });
      }
      
      let data ={
        customerIds:this.customerIds.join(','),
        ascriptionId:val,
        customerState: 1,
      }
      moveCustomer(data)
      .then(res =>{
        if(res.code == 200){
          this.$message({
            message: "移交成功",
            type: "success",
          });
          this.getClientList()
          this.dialogTableVisible = false
        }
      })
      .catch(err=>{
        if(err.code == 201){
          this.$message({
            message: err.message,
            type: "warning",
          });
          this.dialogTableVisible = false
        }
      })
    },
    turnOverShare(){
      this.$confirm( '此操作会将客户移至共享池' , "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        let data ={
          customerIds:this.customerIds.join(','),
          customerState: 2,
        }
        moveCustomer(data)
        .then(res =>{
          if(res.code == 200){
            this.$message({
              message: "移交成功",
              type: "success",
            });
            this.getClientList()
            this.dialogTableVisible = false
          }
        })
        .catch(err=>{
          if(err.code == 201){
          this.$message({
            message: err.message,
            type: "warning",
          });
          this.dialogTableVisible = false
        }
        })

      })
      .catch(() => {});
    },
    detall_tap(id){
      this.$router.push('../../customerDetall?id=' + id)
    },
    edit_tap(id){
      this.$router.push('../../customerInput?id=' + id)
    }
  },
};
</script>

<style lang="scss" scoped>
.mr-16 {
  margin-right: 16px;
}
.exclusiveClients-view {
  background: #ffffff;
  border-radius: 0px 0px 2px 2px;
  padding: 24px;
}
.operation-left {
  .el-button {
    height: 32px;
    background: #51cbcd;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    &:active {
      border-color: #51cbcd;
    }
  }
}
.operation-right {
  .el-button--primary {
    background: #51cbcd;
    border-color: #51cbcd;
  }
  .export {
    border: 1px solid rgba(81, 203, 205, 0.4);
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #51cbcd;
  }
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}
</style>