<template>
  <div class="exclusiveClients-view">
    <div class="d-flex align-items-center justify-content-between mt30">
      <p class="fwbold">与客户企微聊天统计</p>
      <div class="d-flex align-items-center">
          <div class="pt10 pr20 pb10 pl20 w200 d-flex justify-content-between bgf5f6 fs7 hover_pointer">
            <div v-for="(item,index) in dateList"
                :class="currentIndex == index ? 'cblue' : ''"
                :key="index" 
                @click="dateChange(index)">
                {{item.title}}
            </div>
          </div>
        <el-date-picker
          v-model="pickerValue"
          class="ml20 "
          align="right"
          value-format="yyyy-MM-dd"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          @change="getBehaviorData"
        />
      </div>
    </div>
    <div class="bgf5f6 pt10 pl10 pr10 pb10 mt30" v-loading="loading">
      <div class="d-flex flex-wrap-y w100p borderbox">
        <div class="bge3e7 pt50 pl30 pb30 pt50 w24p mb10 chatDataBox mr10" 
             v-for="(item,index) in dataList" 
             :key="index"
             @click="chatDataBoxTap(index)"
             :class="currentChatData == index ? 'currentChatDataBox' : '' ">
          <el-tooltip class="item" effect="dark" :content="item.desc" placement="top-start">
            <p class="fs6 cA3">{{item.title}}</p>
          </el-tooltip>
          <p class="fs12 fwbold mt10">{{behaviorData[item.key]}}{{item.key == 'reply_percentage' ? '%' : ''}}</p>
        </div>
      </div>
      <div id="container">

      </div>
    </div>
    
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
import { behaviorData } from "@/api/qywx";
export default {
  name: "exclusiveClients",
  props:{
    cardId:{
      type :String,
      default:''
    },
  },
  data() {
    return {
      total:0, //总页数
      pageNum:1,
      pageSize:10,
      loading:false,
      dateList:[
        {
          title:'昨日'
        },
        {
          title:'近七日'
        },
        {
          title:'近30日'
        }
      ],
      dataList:[
        {
          title:'发起申请数',
          desc:'成员通过「搜索手机号」、「扫一扫」、「从微信好友中添加」、「从群聊中添加」、「添加共享、分配给我的客户」、「添加单向、双向删除好友关系的好友」、「从新的联系人推荐中添加」等渠道主动向客户发起的好友申请数量',
          key:'new_apply_cnt'
        },
        {
          title:'新增客户数',
          desc:'新添加的客户数量',
          key:'new_contact_cnt'
        },
        {
          title:'聊天总数',
          desc:'主动发送过消息的单聊总数',
          key:'chat_cnt'
        },
        {
          title:'发送消息数',
          desc:'单聊中发送的消息总数',
          key:'message_cnt'
        },
        {
          title:'已回复聊天占比',
          desc:'客户主动发起聊天后，成员在一个自然日内有回复过消息的聊天数/客户主动发起的聊天数比例，不包括群聊，仅在确有聊天时返回',
          key:'reply_percentage'
        },
        {
          title:'平均首次回复时长',
          desc:'单位为分钟，即客户主动发起聊天后，成员在一个自然日内首次回复的时长间隔为首次回复时长，所有聊天的首次回复总时长/已回复的聊天总数即为平均首次回复时长，不包括群聊，仅在确有聊天时返回',
          key:'avg_reply_time'
        },
        {
          title:'删除/拉黑成员的客户数',
          desc:'将成员删除或加入黑名单的客户数',
          key:'negative_feedback_cnt'
        }
      ],
      dataChart:[
        {
          amount: 1,
          city: "发起申请数",
          date: "2022-02-08"
        },
        {
          amount: 2,
          city: "发起申请数",
          date: "2022-02-09"
        },
        {
          amount: 3,
          city: "发起申请数",
          date: "2022-02-10"
        },
        {
          amount: 4,
          city: "发起申请数",
          date: "2022-02-11"
        },
      ],
      currentIndex:0,// 当前查询日期
      currentChatData:0,
      chart:'',
      pickerValue:'',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() || Date.now() - time.getTime() > 180*24*60*60*1000;
        }
      },
      behaviorData:{
        'new_apply_cnt': 0,
        'new_contact_cnt': 0,
        'chat_cnt': 0,
        'message_cnt': 0,
        'reply_percentage': 0,
        'avg_reply_time': 0,
        'negative_feedback_cnt': 0,
      },
      behaviorDataList:[]
    };
  },
  created(){
    this.getBehaviorData()
  },
  mounted(){
    this.initComponent()
  },
  methods: {
    getBehaviorData() {
      var yesterday = this.getDay(-1)
      var startTime = yesterday
      var endTime = yesterday
      if(this.pickerValue) {
        startTime = this.pickerValue[0]
        endTime = this.pickerValue[1]
        let sTime = new Date(startTime).getTime()
        let eTime = new Date(endTime).getTime()
        let cTime = new Date().getTime()
        let t1 = 24*60*60*1000
        if(eTime - sTime > 30*t1) {
          this.$message.error('支持的最大查询跨度为30天');
          return;
        }
        if(eTime - cTime > 180*t1 || sTime - cTime > 180*t1) {
          this.$message.error('最多可获取最近180天内的数据');
          return;
        }
        this.currentIndex = -1
      }

      if(this.currentIndex == 1) {
        startTime = this.getDay(-7)
      } else if(this.currentIndex == 2) {
        startTime = this.getDay(-30)
      }

      let data = {
        cardId: this.cardId,
        startTime: startTime + ' 00:00:00',
        endTime: endTime + ' 23:59:59',
      };
      console.log(data)
      this.loading = true
      behaviorData(data).then((res) => {
        let bd = {
          'new_apply_cnt': 0,
          'new_contact_cnt': 0,
          'chat_cnt': 0,
          'message_cnt': 0,
          'reply_percentage': 0,
          'avg_reply_time': 0,
          'negative_feedback_cnt': 0,
        }
        this.behaviorDataList = res.data
        this.behaviorDataList.forEach(data=>{
          bd['new_apply_cnt'] += data.newApplyCnt || 0
          bd['new_contact_cnt'] += data.newContactCnt || 0
          bd['chat_cnt'] += data.chatCnt || 0
          bd['message_cnt'] += data.messageCnt || 0
          bd['reply_percentage'] += data.replyPercentage || 0.0
          bd['avg_reply_time'] += data.avgReplyTime || 0
          bd['negative_feedback_cnt'] += data.negativeFeedbackCnt || 0
        })
        this.behaviorData = bd
        this.resetChart()
        this.loading = false
      })
      .catch(err=>{
        this.loading = false
      })
    },
    dateChange(index){
      this.currentIndex = index
      this.pickerValue = null
      this.getBehaviorData()
    },
    initComponent() {
      const chart = new Chart({
        container: "container",
        autoFit: true,
        height: 500,
      });
      chart.data(this.dataChart);
      chart.scale({
        month: {
          date: [0, 1],
        },
        amount: {
          nice: true,
        },
      });

      chart.tooltip({
        showCrosshairs: true,
        shared: true,
      });

      chart.axis("amount", {
        label: {
          formatter: (val) => {
            return val;
          },
        },
      });

      chart.line().position("date*amount").color("city").shape("smooth");
      chart.point().position("date*amount").color("city").shape("circle");
      chart.render();
      this.chart = chart
    },
    chatDataBoxTap(index){
      this.currentChatData = index
      this.resetChart()
    },
    resetChart(){
      let bd = {
        'new_apply_cnt': 0,
        'new_contact_cnt': 0,
        'chat_cnt': 0,
        'message_cnt': 0,
        'reply_percentage': 0,
        'avg_reply_time': 0,
        'negative_feedback_cnt': 0,
      }
      this.dataChart = []
      let chartIndex = this.dataList[this.currentChatData];
      console.log(chartIndex)
      this.behaviorDataList.forEach(data=>{
        bd['new_apply_cnt'] = data.newApplyCnt || 0
        bd['new_contact_cnt'] = data.newContactCnt || 0
        bd['chat_cnt'] = data.chatCnt || 0
        bd['message_cnt'] = data.messageCnt || 0
        bd['reply_percentage'] = data.replyPercentage || 0.0
        bd['avg_reply_time'] = data.avgReplyTime || 0
        bd['negative_feedback_cnt'] = data.negativeFeedbackCnt || 0
        let chart = {amount: bd[chartIndex['key']], city: chartIndex['title'], date: this.getDay(0, data.statTime*1000)}
        console.log(chart)
        this.dataChart.push(chart)
      })
      console.log(this.dataChart)

      if (this.chart) {
        this.chart.data(this.dataChart);
        this.chart.render();
      } else {
        this.initComponent()
      }
    },
    getDay(day, date){
      var today = date ? new Date(date) : new Date();
      var targetday_milliseconds = today.getTime() + 1000*60*60*24*day;
      today.setTime(targetday_milliseconds);
      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear+"-"+tMonth+"-"+tDate;
    },
    doHandleMonth(month){
      var m = month;
      if(month.toString().length == 1){
        m = "0" + month;
      }
      return m;
    }
  },
};
</script>

<style lang="scss" scoped>
.mr-16 {
  margin-right: 16px;
}
.exclusiveClients-view {
  background: #ffffff;
  border-radius: 0px 0px 2px 2px;
  padding: 24px;
}
.operation-left {
  .el-button {
    width: 96px;
    height: 32px;
    background: #51cbcd;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    &:active {
      border-color: #51cbcd;
    }
  }
}
.operation-right {
  .el-button--primary {
    background: #51cbcd;
    border-color: #51cbcd;
  }
  .export {
    border: 1px solid rgba(81, 203, 205, 0.4);
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #51cbcd;
  }
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}
.chatDataBox{
  background: #d8e3e7;
  transition: all 0.5s;
  
}
.currentChatDataBox{
  background: rgba(81, 203, 205, 0.15);
  transition: all 0.5s;
  p{
    color: #51cbcd;
  }
}
</style>